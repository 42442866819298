import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import { result } from "lodash"
import SAlert from "components/Alert"
import ProviderInFilter from "./ProviderDistributionActionRequestFilter"
import ProviderDistributionActionRequestFilter from "./ProviderDistributionActionRequestFilter"
import { useDispatch, useSelector } from "react-redux"
import { ProvActionRequestAction } from "store/provider-action-requests/provider-action-request-slice"

const ProviderDistributionActionRequestListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onAllDeleteClicked,
  onAdvancedSearchSubmit,
  onClickDetail,
  onCancelDeleteClicked,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const actionIds = useSelector(state => state.provActionRequests.ids)
  const dispatch = useDispatch()

  const onCheckboxClick = (e, id) => {
    const check = actionIds.includes(id)
    if (check) {
      const updated = actionIds.filter(myId => myId !== id)
      dispatch(ProvActionRequestAction.setIds(updated))
    } else {
      dispatch(ProvActionRequestAction.setNewIds(id))
    }
  }

  const onAllDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure delete?",
    })
    if (results.isConfirmed) {
      onAllDeleteClicked(actionIds)
    }
  }

  const onCancelDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure for cancel?",
    })
    if (results.isConfirmed) {
      onCancelDeleteClicked(actionIds)
    }
  }

  const onParentCheckChange = status => {
    if (status) {
      const all = data?.data.map(dt => dt.id)
      dispatch(ProvActionRequestAction.setIds(all))
    } else {
      dispatch(ProvActionRequestAction.resetIds())
    }
  }

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            deleteBtn={true}
            deleteBtnTitle={"Approve"}
            onAllDeleteHandler={onAllDeleteHandler}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            cancelBtn={true}
            onCancelDeleteHandler={onCancelDeleteHandler}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "ip_id", name: "IP" },
              { value: "province_id", name: "Province" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderDistributionActionRequestFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            onParentCheckChange={onParentCheckChange}
            headers={[
              "SN",
              "REQUEST BY",
              "PROJECT",
              "IP",
              "DISTRIBUTOR",
              "WAREHOUSE DISRIBUTE",
              "PROVINCE",
              "FACILITY",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "UNFPA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "UNIT PRICE (AS PER UNFPA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "DISTRIBUTED QTY PER IP	",
              "DISTRIBUTION DATE",

              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pOut, index) => {
              return (
                <tr key={index}>
                  <th scope="row">
                    {" "}
                    <div className="form-check form-checkbox-outline form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-${pOut.id}`}
                        checked={actionIds.includes(pOut.id)}
                        onChange={e => onCheckboxClick(e, pOut.id)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`checkbox-${pOut.id}`}
                      >
                        {data?.meta?.from + index}
                      </label>
                    </div>
                  </th>
                  <td>{pOut?.user?.email}</td>
                  <td>{pOut?.project?.name}</td>
                  <td>{pOut?.ip?.name}</td>
                  <td>{pOut?.distributor}</td>
                  <td>{pOut?.warehouse_disribute}</td>
                  <td>{pOut?.province?.province}</td>
                  <td>{pOut?.facility?.facility_name}</td>
                  <td>{pOut?.item?.unfp_item_name}</td>
                  <td>{pOut?.item?.ip_item_description}</td>
                  <td>
                    {pOut?.item.uom} &nbsp;
                    {pOut?.item.unfpa_uom_quantity > 1 &&
                      pOut?.item.unfpa_uom_quantity}
                  </td>
                  <td>
                    {pOut?.item.ip_unit_umo} &nbsp;
                    {pOut?.item.ip_uom_quantity > 1 &&
                      pOut?.item.ip_uom_quantity}
                  </td>
                  <td>{pOut?.provider_in?.unit_price_per_unfpa}</td>
                  <td>{pOut?.provider_in?.unit_price_per_ip}</td>
                  <td>{pOut?.provider_in?.batch_number}</td>
                  <td>{pOut?.provider_in?.expire_date}</td>
                  <td>{pOut?.out_quantity}</td>
                  <td>{pOut?.date}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <ActionColumns
                      enableDetail={true}
                      data={pOut}
                      onClickDetail={onClickDetail}
                    />
                  </td>
                </tr>
              )
            }}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderDistributionActionRequestListComponent
