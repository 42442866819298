import ProviderInForm from "components/ProviderIn/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderProjects,
  getProviderProviders,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"
import { storeProviderIn } from "store/provider-in/provider-in-action"

const AddProviderIn = () => {
  document.title = "Add Receipts"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.providerIn)

  const warehosues = useSelector(
    state => state.providerDashboard.providerWarehouses
  )
  const providers = useSelector(
    state => state.providerDashboard.providerProviders
  )

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )

  const items = useSelector(state => state.providerDashboard.ProviderItems)

  const handleSubmit = (values, file) => {
    const forms = new FormData()
    console.log(file)
    forms.append("document", file)
    forms.append("receipts", JSON.stringify(values))
    dispatch(storeProviderIn(forms, navigate))
  }

  useEffect(() => {
    dispatch(getProviderWarehouses())
    dispatch(getProviderProviders())
    dispatch(getProviderItemCategories())
    dispatch(getProviderProjects())
  }, [])

  return (
    <ProviderInForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
      warehosues={warehosues?.data}
      providers={providers?.data}
      categories={categories?.data}
      items={items?.data}
      projects={projects?.data}
    />
  )
}

export default AddProviderIn
