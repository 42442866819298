import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import { result } from "lodash"
import SAlert from "components/Alert"
import ProviderInFilter from "./ProvinceInFilter"
import ProvinceInFilter from "./ProvinceInFilter"
import ProvinceInTotalBox from "./ProvinceInTotalBox"

const ProvinceInListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onAdvancedSearchSubmit,
  permissions,
  onClickDetail,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "expire_date", name: "Exipre Date" },
              { value: "date", name: "Submission Date" },
            ]}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
          />
          <ProvinceInTotalBox totals={data?.totals} />
          <Collapse isOpen={filterOpen}>
            <ProvinceInFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "PROVINCE",
              "HEALTH FACILITY",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "UNFPA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "QUANTITY",
              "RECEIPT DATE",
              "ACTION",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pIn, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{data?.meta?.from + index}</th>
                  <td>{pIn?.project?.name}</td>
                  <td>{pIn?.provider?.title}</td>
                  <td>{pIn?.province?.province}</td>
                  <td>{pIn?.facility?.facility_name}</td>
                  <td>{pIn?.item?.unfp_item_name}</td>
                  <td>{pIn?.item?.ip_item_description}</td>
                  <td>
                    {pIn?.item.uom} &nbsp;
                    {pIn?.item.unfpa_uom_quantity > 1 &&
                      pIn?.item.unfpa_uom_quantity}
                  </td>
                  <td>
                    {pIn?.item.ip_unit_umo} &nbsp;
                    {pIn?.item.ip_uom_quantity > 1 && pIn?.item.ip_uom_quantity}
                  </td>

                  <td>{pIn.batch_number}</td>
                  <td>{pIn.expire_date}</td>
                  <td>{pIn.first_in_qty}</td>
                  <td>{pIn.date}</td>
                  <td>
                    <ActionColumns
                      enableDetail={true}
                      data={pIn}
                      onClickDetail={onClickDetail}
                    />
                  </td>
                </tr>
              )
            }}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProvinceInListComponent
